<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div
        v-if="activeUser && activeUser.name && activeUser.currentRole"
        class="d-sm-flex d-none user-nav"
      >
        <p class="user-name font-weight-bolder mb-0">
          {{ activeUser.name }}
        </p>
        <span class="user-status">{{ activeUser.currentRole.description }} <span v-if="activeUser && activeUser.office">@ {{ activeUser.office.name }}</span></span>
      </div>
      <b-avatar
        v-if="activeUser && activeUser.avatar"
        size="40"
        :src="activeUser.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!activeUser.name"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
      <feather-icon
        v-if="activeUser"
        icon="UserIcon"
        size="22"
      />
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item>

    <b-dropdown-divider /> -->

    <b-dropdown-item
      v-if="activeUser && activeUser.state && activeUser.state === 'activated' && $can('read', 'Setting')"
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      v-if="activeUser && activeUser.state && activeUser.state === 'activated' && $can('read', 'FAQ')"
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      v-if="activeUser && activeUser.roles && activeUser.roles.length > 1 && $can('switch', 'Role')"
      v-b-modal.switch-role-modal
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="RepeatIcon"
        class="mr-50"
      />
      <span>Switch Role</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="activeUser && activeUser.state && activeUser.state === 'activated' && $can('access_patient_portal', 'Office')"
      link-class="d-flex align-items-center"
      @click="patientPortalHandler"
    >
      <feather-icon
        size="16"
        icon="ArrowRightIcon"
        class="mr-50"
      />
      <span>Patient Portal</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
    <switch-role-modal />
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar, VBModal,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import { mapGetters, mapActions } from 'vuex'
import SwitchRoleModal from '@/views/components/modal/SwitchRoleModal.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
    SwitchRoleModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      avatarText,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
  methods: {
    ...mapActions('auth', ['logoutUser']),
    ...mapActions('office', ['accessPatientPortal']),
    async logout() {
      await this.logoutUser()
      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login', replace: true })
    },
    async patientPortalHandler() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You will be signed out and redirected to the patient portal.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, take me there!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        this.loading = true
        try {
          if (result.value) {
            const requestObject = {
              office_id: this.activeUser.office_id,
            }
            await this.accessPatientPortal(requestObject).then(() => {
              this.$router.go()
            })
          }
        } catch (error) {
          console.debug(`Patient Portal handler error in UserDropdown.vue: ${error.message}`)
          this.$swal({
            title: 'Error!',
            icon: 'error',
            html: this.$t('errors.generic'),
            buttonsStyling: true,
          })
        } finally {
          this.loading = false
        }
      })
    },
  },
}
</script>
