<template>
  <b-modal
    id="switch-role-modal"
    :title="$t('page_titles.switch_role')"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @ok="handleOk"
    @show="resetModal"
    @hidden="resetModal"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <validation-observer
            ref="switchRoleForm"
          >
            <b-overlay
              :show="loading"
              :variant="overlayVariant"
              :opacity="overlayOpacity"
              rounded="sm"
            >
              <b-form @submit.stop.prevent="handleSubmit">
                <b-form-group
                  label="Select Role"
                  label-for="role-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Role"
                    vid="role-select"
                    rules="required"
                  >
                    <v-select
                      id="role-select"
                      v-model="desiredRole"
                      :reduce="role => role.id"
                      name="role-select"
                      label="description"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :state="errors.length > 0 ? false:null"
                      :options="userRoles"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="desiredRole && desiredRole !== activeUser.default_role.id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Default Role"
                    vid="default-role-checkbox"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="default-role-checkbox"
                      v-model="makeDefault"
                      name="default-role-checkbox"
                      :unchecked-value="false"
                    >
                      <span>Set this role as the default role.</span>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form>

              <!-- server alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="serverError !== null"
              >
                <div
                  class="alert-body"
                >
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  <span v-html="serverError" />
                </div>
              </b-alert>

            </b-overlay>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BAlert, BOverlay, BModal, BForm, BFormGroup, BFormCheckbox, BContainer, BRow, BCol,
} from 'bootstrap-vue'
import {
  required,
  email,
} from '@validations'
import {
  heightFade,
} from '@core/directives/animations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAlert,
    BOverlay,
    BModal,
    BForm,
    BFormGroup,
    BContainer,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      desiredRole: null,
      makeDefault: false,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      required,
      email,
      serverError: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    userRoles() {
      let userRoles = []
      try {
        const { roles } = this.activeUser
        userRoles = roles.filter(r => r.name !== 'admin' && r.name !== 'patient')
      } catch (e) {
        console.debug('Could not load roles in SwitchRoleModal')
      }
      return userRoles
    },
  },
  methods: {
    ...mapActions('user', ['updateUser']),
    ...mapActions('auth', ['switchRole']),

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.switchRoleForm.validate().then(async success => {
        if (success) {
          try {
            this.loading = true
            this.serverError = null
            // // Submit the form
            await this.switchRole(this.desiredRole)
            // Set default if present
            if (this.makeDefault) {
              await this.updateUser({ id: this.activeUser.id, default_role_id: this.desiredRole })
            }
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('switch-role-modal')
            })
            // this.$router.go().then(() => {
            //   // this.$toast({
            //   //   component: ToastificationContent,
            //   //   position: 'top-right',
            //   //   props: {
            //   //     title: 'Success',
            //   //     icon: 'CheckIcon',
            //   //     variant: 'success',
            //   //     text: 'Role switched successfully!',
            //   //   },
            //   // })
            // })
            // this.$emit('success', { message: 'Role switched successfully!' })
          } catch (e) {
            console.log(`Submit error in SwitchRoleModal.vue: ${e.message}`)
            this.serverError = this.$t('errors.generic')
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: 'Could not switch role',
              },
            })
          } finally {
            this.loading = false
            this.$router.go()
          }
        }
      })
    },
    resetModal() {
      this.desiredRole = null
    },
    onSuccess(event) {
      this.$emit('success', event)
    },
    onError(event) {
      this.$emit('error', event)
    },
  },
}
</script>

<style lang="scss">
  .is-invalid {
    .vs__dropdown-toggle {
      border-color: #ea5455 !important;
    }
  }
</style>
